import React from "react";
import StopWatch from "../components/StopWatch/StopWatch";
import DateTime from "../components/DateTime/DateTime";
import Header from "../components/Header/Header";
import Hero from "../components/Hero/Hero";
import TopBar from "../components/Header/TopBar";

function HomePage() {
  return (
    <div>
      <TopBar />
      <div className="home-page">
        {/* <Header /> */}
        <Hero />
      </div>
    </div>
  );
}

export default HomePage;
