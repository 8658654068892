import React, { useEffect, useState, createContext } from "react";
import toast, { Toaster } from "react-hot-toast";

export const AdminContext = createContext();

export default function AdminContextProvider({ children }) {
  const [users, setUsers] = useState([]);
  const [fetchingUsers, setFetchingUsers] = useState(false);
  const [products, setProducts] = useState([]);
  const [fetchingProducts, setFetchingProducts] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [fetchingAdmins, setFetchingAdmins] = useState(false);
  const [subscribers, setSubscribers] = useState([]);
  const [fetchingSubscribers, setFetchingSubscribers] = useState(false);

  // Function to fetch and update users
  const fetchUsers = async () => {
    setFetchingUsers(true);
    try {
      const response = await fetch(
        "https://super-admin-backend-gold.vercel.app/admin/"
      );
      const data = await response.json();
      setUsers(data);
      setFetchingUsers(false);
    } catch (error) {
      console.error(error);
            setFetchingUsers(false);
    } finally {
      setFetchingUsers(false);
    }
  };

  const fetchProducts = async () => {
    setFetchingProducts(true);
    try {
      const response = await fetch(
        "https://super-admin-backend-gold.vercel.app/domain/"
      );
      const data = await response.json();
      setProducts(data);
      setFetchingProducts(false);
    } catch (error) {
      console.error(error);
            setFetchingProducts(false);
    } finally {
      setFetchingProducts(false);
    }
  };

  const fetchAdmins = async () => {
    setFetchingAdmins(true);
    try {
      const response = await fetch(
        "https://super-admin-backend-gold.vercel.app/admin/getAdmins"
      );
      const data = await response.json();

      setAdmins(data);
      setFetchingAdmins(false);
    } catch (error) {
      console.error(error);
      
      setFetchingAdmins(false);
    } finally {
      setFetchingAdmins(false);
    }
  };

  const fetchSubscribers = async () => {
    setFetchingSubscribers(true);
    console.log("calling subsc");
    try {
      const response = await fetch(
        "https://super-admin-backend-gold.vercel.app/admin/getSubscribers"
      );
      const data = await response.json();
      console.log("RESPONSE DTata is ", data);
      setSubscribers(data);
      setFetchingSubscribers(false);
    } catch (error) {
      console.error(error);
      
      setFetchingSubscribers(false);
    } finally {
      setFetchingSubscribers(false);
    }
  };

  // Initial fetch
  useEffect(() => {
    fetchUsers();
    fetchProducts();
    fetchAdmins();
    fetchSubscribers();
  }, []);

  // Refresh function to be used from anywhere
  const refreshUsers = () => {
    fetchUsers();
  };

  const refreshProducts = () => {
    fetchProducts();
  };

  const refreshAdmins = () => {
    fetchAdmins();
  };

  const refreshSubscribers = () => {
    fetchSubscribers();
  };

  const createAdmin = async (adminData) => {
    console.log("Entereeda admin context - created admin");
    console.log("Admin data is :", adminData);
    try {
      const response = await fetch(
        "https://super-admin-backend-gold.vercel.app/admin/createAdmin",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(adminData),
        }
      );

      if (response.ok) {
        toast.success("Admin created successfully!");
        return await response.json();
      } else {
        const errorData = await response.json();
        if (errorData.details && errorData.details.length > 0) {
          // Show detailed validation errors
          toast.error(`Validation error: ${errorData.details.join(", ")}`);
        } else {
          // Show generic error message
          toast.error(`Error: ${errorData.message}`);
        }
      }
    } catch (error) {
      console.error("Error creating admin:", error);
      toast.error(`Failed to create admin: `);
    }
  };

  return (
    <AdminContext.Provider
      value={{
        users,
        fetchingUsers,
        refreshUsers,
        products,
        fetchingProducts,
        refreshProducts,
        admins,
        fetchingAdmins,
        refreshAdmins,
        subscribers,
        fetchingSubscribers,
        refreshSubscribers,
        createAdmin,
      }}
    >
      {children}
      <Toaster />
    </AdminContext.Provider>
  );
}
