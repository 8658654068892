// ---- Importing required dependencies (External) ----
// ---- Packages ----
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Transition } from "@headlessui/react";
import $ from "jquery";
import { Button } from "@mui/material";
// ---- Icons ----
import { BsPersonFill, BsPersonCircle } from "react-icons/bs";
import { AiFillProfile } from "react-icons/ai";
import { RiLogoutBoxFill } from "react-icons/ri";

// ---- Importing required dependencies (Local) ----
// ---- Dependent Components ----

// ---- Styles ----
import "./Header.css";
// ---- Context ----
import { AuthContext } from "../../context/AuthContextProvider";
// ---- Data ----
import img1 from "./../../Assets/Media/Logos/iTimerLogo.png";
import { useSearchContext } from "../../context/SearchContext";

import { Spin as Hamburger } from "hamburger-react";
import MobileSidebar from "./MobileSidebar";
import MobileOptions from "./MobileOptions";
import MainHeaderDropdownMenu from "./MainHeaderDropdownMenu";

// ---- Main function - Header - Present in the entire application
function Header({ bgColor }) {
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(true);
  const { isAuth, logoutme, loginUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const { searchTerm, setSearchTerm } = useSearchContext();

  // Handle click event for checking whether the user is logged in or not.Then, directing the user to Login page if he/she is not logged in.
  let handleClick = () => {
    if (isAuth === true) {
      logoutme();
    } else {
      navigate("/login");
    }
  };

  // Handle click event for checking whether the user is logged in or not.Then, directing the user to Signup page if he/she is not logged in.
  let handleClick2 = () => {
    if (isAuth === true) {
      return;
    } else {
      navigate("/signup");
    }
  };

  // Handle click event for Authentication check and then Directing to Profile page of the user is he/she is logged in.
  let getProfile = () => {
    if (isAuth === true) {
      setOpen(false);
      navigate("/Dashboard");
    } else {
      return;
    }
  };

  // Handle close event for the menu
  const handleClose = (e) => {
    setOpen(false);
  };

  // Update Navbar styling based on scroll position
  useEffect(() => {
    $(window).on("scroll", function () {
      $(window).scrollTop()
        ? $(".Navbar").addClass("black")
        : $(".Navbar").removeClass("black");
    });
  }, []);

  const handleSearchSubmit2 = (event) => {
    setSearchTerm(""); // Update the searchTerm in the context
    navigate("/");
  };

  return (
    <div>
      <div className={`Navbar ${bgColor}`}>
        <div className="flex items-center justify-between h-full Navbar-part">
          {/* Brand Logo */}
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <button
                className="w-fit brand-logo"
                onClick={handleSearchSubmit2}
              >
                <img
                  className="h-12 w-26 pr-14 header-logo"
                  src={img1}
                  alt="Workflow"
                />
              </button>
            </div>
          </div>
          <div className="hidden lg:block flex gap-[12px]">
            <Link to="/full-screen-date-and-time">Date & Time</Link>
            <Link to="/full-screen-stop-watch">Stop Watch</Link>
          </div>
          {/* Desktop Menu */}
          <div className="hidden lg:block h-full">
            <div className=" flex items-center space-x-8 h-full">
              {isAuth ? (
                <MainHeaderDropdownMenu />
              ) : (
                <Link to="/login">
                  <Button
                    type="submit"
                    size="medium"
                    sx={{
                      width: "100%",
                      background: "#ce9500",
                      color: "black",
                      textAlign: "center",
                      padding: "4px 16px ",
                      marginTop: "2px",
                      border: "1px solid white",
                      "&:hover": {
                        background: "#101c4c",
                        color: "white",
                      },
                    }}
                  >
                    Advertise Here
                  </Button>
                </Link>
              )}
            </div>
          </div>
          <div className="lg:hidden flex gap-[12px]">
            {isAuth ? <MobileSidebar /> : null}
            <MobileOptions />
          </div>
          {/* Mobile Menu */}
        </div>

        {/* Mobile Menu Transition */}
        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
          className="mobile-menu-part"
        >
          {(ref) => (
            <div className="lg:hidden" id="mobile-menu">
              <div
                ref={ref}
                className="px-2 pt-2 pb-3 space-y-1 sm:px-3 mobile-nav-items"
              >
                {/* Domain Search */}
                <Link
                  to="/DomainNameSearch"
                  className="text-black block px-3 py-2 rounded-md "
                >
                  Domain Search
                </Link>

                {/* About .GOLD */}
                <Link
                  to="/Goldhistory"
                  className="text-black block px-3 py-2 rounded-md "
                >
                  About .GOLD
                </Link>

                {/* Blogs */}
                <Link
                  to="/Blogs"
                  className="text-black hover:text-black block px-3 py-2 rounded-md "
                >
                  <p>Blogs</p>
                </Link>

                {/* Live Sites */}
                <Link
                  to="/LiveSites"
                  className="text-black hover:text-black block px-3 py-2 rounded-md "
                >
                  <p>Live Sites</p>
                </Link>
                {/* Help */}
                <Link
                  to="/Contact"
                  className="text-black hover:text-black block px-3 py-2 rounded-md "
                >
                  Help
                </Link>

                {/* Cart Widgets */}

                {/* Login */}

                {isAuth ? (
                  <div>
                    <div
                      className="flex flex-row logged-in-item-heading "
                      onClick={getProfile}
                    >
                      Profile
                    </div>
                    <Link
                      to="/Dashboard/Domains"
                      className="text-black hover:text-black block px-3 py-2 rounded-md "
                      onClick={setOpen(false)}
                    >
                      My Domains
                    </Link>
                    {/* Logout */}
                    <div
                      className="flex flex-row logged-in-item-heading "
                      onClick={handleClick}
                    >
                      Logout
                    </div>
                  </div>
                ) : (
                  <div>
                    <Link
                      to="/Login"
                      className="text-black hover:text-black block px-3 rounded-md text-base font-medium border-solid border-2 border-black py-1 px-4 w-fit ml-[15px]"
                    >
                      Login
                    </Link>
                  </div>
                )}
              </div>
            </div>
          )}
        </Transition>
      </div>
    </div>
  );
}

export default Header;
