import React from "react";
import StopWatch from "../components/StopWatch/StopWatch";
import TopBar from "../components/Header/TopBar";

function StopWatchPage() {
  return (
    <div>
      <TopBar />
      <div className="home-page !justify-center pt-[120px]">
        <StopWatch />
      </div>
    </div>
  );
}

export default StopWatchPage;
