import React from "react";
import StopWatch from "../components/StopWatch/StopWatch";
import TopBar from "../components/Header/TopBar";
import Timer from "../components/Timer/Timer";
import TimerItem from "../components/Timer/TimerItem";
import SingleTimer from "../components/Timer/SingleTimer";

function TimerPage() {
  return (
    <div>
      <TopBar />
      <div className="home-page !justify-center pt-[120px]">
        {/* <Timer /> */}
        {/* <TimerItem /> */}
        <SingleTimer />
      </div>
    </div>
  );
}

export default TimerPage;
