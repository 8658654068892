import React, { useState, useEffect, useRef } from "react";
import StopWatchDisplay from "./StopWatchDisplay";

function StopWatch() {
  const [time, setTime] = useState(() => {
    const savedTime = localStorage.getItem("elapsedTime");
    return savedTime ? parseInt(savedTime, 10) : 0;
  });
  const [isRunning, setIsRunning] = useState(() => {
    const savedRunningState = localStorage.getItem("isRunning");
    return savedRunningState === "true";
  });
  const [laps, setLaps] = useState(() => {
    const savedLaps = localStorage.getItem("laps");
    return savedLaps ? JSON.parse(savedLaps) : [];
  });
  const [lastLapTime, setLastLapTime] = useState(0);
  const [buttonState, setButtonState] = useState(() => {
    const savedButtonState = localStorage.getItem("buttonState");
    return savedButtonState
      ? JSON.parse(savedButtonState)
      : { startPause: true, lap: false, reset: false };
  });

  const animationFrameRef = useRef(null);

  useEffect(() => {
    if (isRunning) {
      const storedStartTime = localStorage.getItem("startTime");
      const startTime = storedStartTime
        ? parseInt(storedStartTime, 10)
        : Date.now() - time;
      localStorage.setItem("startTime", startTime);

      animationFrameRef.current = requestAnimationFrame(updateTime);
    } else {
      cancelAnimationFrame(animationFrameRef.current);
    }

    return () => cancelAnimationFrame(animationFrameRef.current);
  }, [isRunning]);

  useEffect(() => {
    if (!isRunning) {
      localStorage.setItem("elapsedTime", time);
    }
  }, [time, isRunning]);

  const updateTime = () => {
    const startTime = parseInt(localStorage.getItem("startTime"), 10);
    setTime(Date.now() - startTime);
    animationFrameRef.current = requestAnimationFrame(updateTime);
  };

  useEffect(() => {
    localStorage.setItem("buttonState", JSON.stringify(buttonState));
  }, [buttonState]);

  useEffect(() => {
    localStorage.setItem("laps", JSON.stringify(laps));
  }, [laps]);

  const handleStart = () => {
    const currentStartTime = Date.now() - time;
    localStorage.setItem("startTime", currentStartTime);
    setIsRunning(true);
    setButtonState({ startPause: true, lap: true, reset: false });
    localStorage.setItem("isRunning", true);
  };

  const handlePause = () => {
    setIsRunning(false);
    setButtonState({ startPause: true, lap: false, reset: true });
    localStorage.setItem("isRunning", false);
  };

  const handleReset = () => {
    setIsRunning(false);
    setTime(0);
    setLaps([]);
    setLastLapTime(0);
    setButtonState({ startPause: true, lap: false, reset: false });
    localStorage.removeItem("elapsedTime");
    localStorage.removeItem("startTime");
    localStorage.setItem("isRunning", false);
    localStorage.setItem("laps", JSON.stringify([]));
  };

  const handleLap = () => {
    const currentSystemTime = `${new Date().toLocaleString("en-US", {
      weekday: "short",
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    })}.${new Date().getMilliseconds().toString().padStart(3, "0")}`;
    const lapInterval = time - lastLapTime;
    setLaps([
      ...laps,
      {
        lapNumber: laps.length + 1,
        interval: formatTimeDetailed(lapInterval),
        lapTime: formatTimeDetailed(time),
        systemTime: currentSystemTime,
      },
    ]);
    setLastLapTime(time);
  };

  const formatTimeDetailed = (time) => {
    const hours = Math.floor(time / 3600000);
    const minutes = Math.floor((time % 3600000) / 60000);
    const seconds = Math.floor((time % 60000) / 1000);
    const milliseconds = Math.floor(time % 1000);
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}.${milliseconds
      .toString()
      .padStart(3, "0")}`;
  };

  const formatTime = (time) => {
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);
    const milliseconds = Math.floor((time % 1000) / 10); // Convert to centiseconds
    return { days, hours, minutes, seconds, milliseconds };
  };

  const { days, hours, minutes, seconds, milliseconds } = formatTime(time);

  return (
    <StopWatchDisplay
      days={days}
      hours={hours}
      minutes={minutes}
      seconds={seconds}
      milliseconds={milliseconds}
      isRunning={isRunning}
      handlePause={handlePause}
      handleStart={handleStart}
      handleLap={handleLap}
      handleReset={handleReset}
      buttonState={buttonState}
      laps={laps}
    />
  );
}

export default StopWatch;
