import React, { createContext, useContext, useEffect, useState } from "react";
import { AuthContext } from "./AuthContextProvider";

// Define the shape of the context value
const SearchContext = createContext({
  searchTerm: "",
  setSearchTerm: () => {},
});

// Custom hook to use the SearchContext
export const useSearchContext = () => {
  return useContext(SearchContext);
};

// SearchProvider component to wrap around the parts of the app that need access to the context
export const SearchProvider = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [collapsed, setCollapsed] = useState(false);

  const [activeItem, setActiveItem] = useState("Personal Info");

  const handleMenuItemClick = (item) => {
    setActiveItem(item);
  };

  const handleToggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <SearchContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
        collapsed,
        handleToggleSidebar,
        handleMenuItemClick,
        activeItem,
        setActiveItem,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
