import React from "react";
import Header from "../components/Header/Header";
import KnowledgeBase from "../components/Dashboard/KnowledgeBase";

function KnowledgeBasePage() {
  return (
    <div>
      <Header bgColor="NavBackground" />
      <KnowledgeBase />
    </div>
  );
}

export default KnowledgeBasePage;
