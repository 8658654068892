import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function DateTime() {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer); // Cleanup the timer on component unmount
  }, []);

  const hours = currentTime.getHours();
  const minutes = currentTime.getMinutes();
  const seconds = currentTime.getSeconds();

  const weekday = currentTime.toLocaleDateString(undefined, {
    weekday: "long",
  });

  const dateWithoutWeekday = currentTime.toLocaleDateString(undefined, {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <div className="p-4 h-full">
      <div className="mockbrowser-Outer">
        <div className="mockup-browser border-base-300 border mockbrowser-box ">
          <div className="mockup-browser-toolbar">
            <div className="input border-base-300 border bg-white">
              <Link
                to="/personal-ad-page-for-brands-with-minimum-investment"
                className="ad-brand"
                target="_blank"
              >
                Advertise your brand here!
              </Link>
            </div>
          </div>
          <div className="border-t border-[#177087]">
            <div className="px-[40px] pt-[32px]">
              <div className="text-3xl md:text-4xl font-bold mb-2">
                {weekday}
              </div>
              <div className="text-xl md:text-6xl font-bold w-full">
                {dateWithoutWeekday}
              </div>
            </div>
            <div className="border-base-300 flex justify-center  px-[40px] py-[32px]">
              <div className="grid grid-flow-col gap-[16px] text-center auto-cols-max">
                <div className="flex flex-col p-2 timer-time-box rounded-box text-neutral-content">
                  <span className="timer-time countdown font-mono text-[24px] sm:text-5xl md:text-7xl lg:text-8xl  xl:text-9xl">
                    <span style={{ "--value": hours }}></span>
                  </span>
                  <span className="timer-time text-sm md:text-lg lg:text-xl">
                    hours
                  </span>
                </div>
                <div className="flex flex-col p-2 timer-time-box rounded-box text-neutral-content">
                  <span className="timer-time countdown font-mono text-[24px] sm:text-5xl md:text-7xl lg:text-8xl  xl:text-9xl">
                    <span style={{ "--value": minutes }}></span>
                  </span>
                  <span className="timer-time text-sm md:text-lg lg:text-xl">
                    min
                  </span>
                </div>
                <div className="flex flex-col p-2 timer-time-box rounded-box text-neutral-content">
                  <span className="timer-time countdown font-mono text-[24px] sm:text-5xl md:text-7xl lg:text-8xl  xl:text-9xl">
                    {/* <span>{seconds.toString().padStart(2, "0")}</span> */}
                    <span style={{ "--value": seconds }}></span>
                  </span>
                  <span className="timer-time text-sm md:text-lg lg:text-xl">
                    sec
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DateTime;
