import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Slider, { SliderThumb } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import "./Elements.css";
import "./Elements.css";
import { Typography } from "@mui/material";
import ButtonGroup from "@mui/material/ButtonGroup";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import { Dialog } from "@mui/material";

function Elements() {
  return <div></div>;
}

export function AButton({ children, startIcon, endIcon }) {
  return (
    <Button startIcon={startIcon} endIcon={endIcon} className="timer-btn">
      {children}
    </Button>
  );
}

export function AButtonFit({ children, endIcon }) {
  return (
    <Button
      variant="contained"
      size="large"
      sx={{
        width: "fit-content",
        color: "black",
        borderRadius: "15px",
        background: "none",
        "&:hover": {
          background: "none",
        },
      }}
    >
      {children}
    </Button>
  );
}

export function AButton2({ children, startIcon, endIcon, width, color }) {
  return (
    <Button
      variant="contained"
      startIcon={startIcon}
      endIcon={endIcon}
      size="large"
      sx={{
        width: width ? width : "100%",
        border: "1px solid white",
        background: "var(--secondary-bg) !important",
        color: color ? color : "var(--text-primary) !important",
        "&:hover": {
          background: "none",
        },
      }}
    >
      {children}
    </Button>
  );
}

export function AButton3({ children, startIcon, endIcon, width }) {
  return (
    <Button
      variant="contained"
      startIcon={startIcon}
      endIcon={endIcon}
      size="large"
      sx={{
        width: width ? width : "100%",
        border: "1px solid white",
        background: "var(--primary-bg) !important",
        color: "var(--text-secondary) !important",
        "&:hover": {
          background: "none",
        },
      }}
    >
      {children}
    </Button>
  );
}

export function WaveButton({ children, startIcon, endIcon, width }) {
  return (
    <Button
      variant="contained"
      startIcon={startIcon}
      endIcon={endIcon}
      size="large"
      sx={{
        width: width ? width : "100%",
      }}
      className="wave-animation"
    >
      {children}
    </Button>
  );
}

export function AHeading({ children, color }) {
  return (
    <Typography
      variant="h2"
      component="h1"
      gutterBottom
      sx={{ fontWeight: "700", color: color }}
    >
      {children}
    </Typography>
  );
}

export function ASubHeading({ children, color }) {
  return (
    <Typography
      variant="h2"
      component="h1"
      gutterBottom
      sx={{ fontWeight: "700", color: color, fontSize: "20px" }}
    >
      {children}
    </Typography>
  );
}

export function ATitle({ children, color }) {
  return (
    <Typography
      variant="h2"
      component="h1"
      gutterBottom
      sx={{ fontWeight: "700", color: color, fontSize: "32px" }}
    >
      {children}
    </Typography>
  );
}

export function AText({ children, color }) {
  return (
    <Typography
      variant="body2"
      component="h1"
      gutterBottom
      sx={{ fontWeight: "400", color: color, fontSize: "1rem" }}
    >
      {children}
    </Typography>
  );
}

export const PrettoSlider = styled(Slider)({
  color: "#64432f",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&::before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#64432f",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&::before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

export function CustomButtonGroup({ buttons, filterType }) {
  return (
    <ButtonGroup
      variant="contained"
      aria-label="button group"
      sx={{
        width: "fit-content",
        "& > span": {
          zIndex: 3,
          background:
            "linear-gradient(to top, transparent, rgba(255 255 255 / 0.6), transparent)",
        },
      }}
    >
      {buttons.map((button, index) => (
        <React.Fragment key={button.label}>
          <Button
            onClick={button.onClick}
            sx={{
              backgroundColor:
                filterType === button.value ? "#B99653" : "#f8f6ee",
              color: filterType === button.value ? "white" : "#333",
              "&:hover": {
                backgroundColor:
                  filterType === button.value ? "#93693b" : "#ede7d4",
                color: filterType === button.value ? "white" : "#333",
              },
              "&:first-of-type": {
                borderRight: "1px solid #B99653",
              },
              // Style for middle buttons
              "&:not(:first-of-type):not(:last-of-type)": {
                borderRight: "1px solid #B99653",
              },
            }}
          >
            {button.label}
          </Button>
          {index < buttons.length - 1 && (
            <Divider
              orientation="vertical"
              flexItem
              sx={{ borderColor: "#B99653" }}
            />
          )}
        </React.Fragment>
      ))}
    </ButtonGroup>
  );
}

export function CustomTextField({
  label,
  value,
  onChange,
  isRequired,
  ...props
}) {
  return (
    <TextField
      label={label}
      variant="outlined"
      size="small"
      required={isRequired}
      value={value}
      onChange={onChange}
      {...props}
      sx={{
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "#b99653", // Custom border color
          },
          "&:hover fieldset": {
            borderColor: "#ded0aa", // Border color on hover
          },
          "&.Mui-focused fieldset": {
            borderColor: "#563a2d", // Border color on focus
          },
        },
        "& .MuiInputLabel-root": {
          "&.Mui-focused": {
            color: "#563a2d", // Custom label color on focus
            background: "linear-gradient(90deg, #cca97cdb, #fcdb6a, #e2be6d)", // Custom gradient background on focus
            padding: "0 4px", // Optional: padding to adjust for the label background
            fontWeight: "600",
          },
        },
      }}
    />
  );
}

// Reusable Checkbox component
export function CustomCheckbox({ label, checked, onChange, ...props }) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          sx={{
            "&:hover": {
              background: "linear-gradient(90deg, #cca97cdb, #fcdb6a, #e2be6d)",
            },
            "&.Mui-checked": {
              color: "#b99653",
              "&:hover": {
                background: "white",
              },
            },
          }}
        />
      }
      label={label}
    />
  );
}

export function CustomSelect({ label, value, onChange, options }) {
  return (
    <FormControl size="small" sx={{ minWidth: 120, margin: "0px" }}>
      <InputLabel
        sx={{
          color: "#b99653",
          "&.Mui-focused": {
            color: "#b99653",
          },
          color: "#563a2d", // Custom label color on focus
          background: "linear-gradient(90deg, #cca97cdb, #fcdb6a, #e2be6d)", // Custom gradient background on focus
          padding: "0 4px", // Optional: padding to adjust for the label background
          fontWeight: "600",
          padding: "0 4px",
          borderRadius: "4px",
          zIndex: 1,
        }}
      >
        {label}
      </InputLabel>
      <Select
        value={value}
        onChange={onChange}
        label={label}
        size="small"
        sx={{
          padding: "1.5px 0",
          "& .MuiOutlinedInput-root": {
            padding: "0", // Remove default padding to ensure small size
            height: "8px !important", // Set height to match small size
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#b99653", // Custom border color
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ded0aa", // Border color on hover
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#563a2d", // Border color on focus
          },
          "& .MuiSelect-icon": {
            color: "#b99653", // Custom color for dropdown icon
          },
          "& .MuiSelect-select": {
            backgroundColor: "transparent", // Transparent background
            color: "#b99653", // Custom text color
            padding: "13px 12px !important", // Adjust padding to fit small size
            minHeight: "6px !important", // Ensure minimum height matches small size
            display: "flex",
            alignItems: "center", // Center align text and icon
          },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              bgcolor: "#cab27a", // Background color of the menu
              color: "#ffffff", // Text color in the menu
              "& .MuiMenuItem-root": {
                color: "#ffffff",
              },
              "& .MuiMenuItem-root.Mui-selected": {
                backgroundColor: "#ab8547",
              },
              "& .MuiMenuItem-root.Mui-selected:hover": {
                backgroundColor: "#93693b",
              },
            },
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export function CountdownTimer({ endTime }) {
  const calculateTimeLeft = (endTime) => {
    const difference = new Date(endTime) - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(endTime));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(endTime));
    }, 1000);

    return () => clearInterval(timer); // Cleanup interval on component unmount
  }, [endTime]);

  return (
    <div>
      {Object.keys(timeLeft).length > 0 ? (
        <div>
          <span>{timeLeft.days}d </span>
          <span>{timeLeft.hours}h </span>
          <span>{timeLeft.minutes}m </span>
          <span>{timeLeft.seconds}s</span>
        </div>
      ) : (
        <span>Time's up!</span>
      )}
    </div>
  );
}

export default Elements;

export const customModal = ({ handleClose, open, children }) => {
  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        {children}
      </Dialog>
    </>
  );
};
