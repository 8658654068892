import { IoIosGlobe } from "react-icons/io";
import { MdOutlinePerson } from "react-icons/md";
import { RiAuctionLine, RiAdminLine, RiProductHuntLine } from "react-icons/ri";
import { TfiUser } from "react-icons/tfi";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { CiFileOn } from "react-icons/ci";
import React from "react";
import { FaRegUserCircle } from "react-icons/fa";
import { SVGIcons } from "./SVGIcons";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { BsTicket } from "react-icons/bs";
import { MdEdit } from "react-icons/md";
import { IoKeyOutline } from "react-icons/io5";
import { GrUserAdmin } from "react-icons/gr";
import { TbUsersGroup } from "react-icons/tb";
import { PiUsersLight } from "react-icons/pi";

export const allLinks = [
  {
    title: "Account",
    icon: <MdOutlinePerson className="text-[20px]" />,
    links: [
      {
        id: 1,
        name: "Personal Info",
        link: "/Dashboard",
        icon: <FaRegUserCircle className="text-[20px]" />,
        module: "account",
        action: "read",
      },
      {
        id: 2,
        name: "Edit Request",
        link: "/Dashboard/EditAccountDetails",
        icon: <MdEdit className="text-[20px]" />,
        module: "account",
        action: "write",
      },
      {
        id: 3,
        name: "Change Password",
        link: "/Dashboard/ChangePassword",
        icon: <IoKeyOutline className="text-[20px]" />,
        module: "account",
        action: "update",
      },
    ],
  },
  {
    title: "Admins",
    icon: <RiAdminLine className="text-[20px]" />,
    links: [
      {
        id: 1,
        name: "All Admins",
        link: "/Dashboard/AllAdmins",
        icon: <GrUserAdmin className="text-[20px]" />,
        module: "admins",
        action: "read",
      },
      {
        id: 2,
        name: "Create Admin",
        link: "/Dashboard/CreateAdmin",
        icon: SVGIcons[0].clog,
        module: "admins",
        action: "write",
      },
    ],
  },
  {
    title: "Products",
    icon: <IoIosGlobe className="text-[20px]" />,
    links: [
      {
        id: 6,
        name: "All Products",
        link: "/Dashboard/AllProducts",
        icon: <RiProductHuntLine className="text-[20px]" />,
        module: "products",
        action: "read",
      },
    ],
  },
  {
    title: "Bids",
    icon: <RiAuctionLine className="text-[20px]" />,
    links: [
      {
        id: 7,
        name: "All Bids",
        link: "/Dashboard/AllBids",
        icon: <RiAuctionLine className="text-[20px]" />,
        module: "bids",
        action: "read",
      },
    ],
  },
  {
    title: "Users",
    icon: <HiOutlineUserGroup className="text-[20px]" />,
    links: [
      {
        id: 4,
        name: "All Users",
        link: "/Dashboard/AllUsers",
        icon: <TbUsersGroup className="text-[20px]" />,
        module: "users",
        action: "read",
      },
    ],
  },
  {
    title: "Tickets",
    icon: <BsTicket className="text-[20px]" />,
    links: [
      {
        id: 8,
        name: "All Tickets",
        link: "/Dashboard/AllTickets",
        icon: <BsTicket className="text-[20px]" />,
        module: "tickets",
        action: "read",
      },
    ],
  },
  {
    title: "Subscribers",
    icon: <TfiUser className="text-[20px]" />,
    links: [
      {
        id: 9,
        name: "All Subscribers",
        link: "/Dashboard/AllSubscribers",
        icon: <PiUsersLight className="text-[20px]" />,
        module: "subscribers",
        action: "read",
      },
    ],
  },
  {
    title: "Blogs",
    icon: <HiOutlinePencilAlt className="text-[20px]" />,
    links: [
      {
        id: 10,
        name: "All Blogs",
        link: "/Dashboard/AllBlogs",
        icon: <HiOutlinePencilAlt className="text-[20px]" />,
        module: "blogs",
        action: "read",
      },
    ],
  },
  {
    title: "Logs",
    icon: <CiFileOn className="text-[20px]" />,
    links: [
      {
        id: 11,
        name: "System Logs",
        link: "/Dashboard/SystemLogs",
        icon: <CiFileOn className="text-[20px]" />,
        module: "logs",
        action: "read",
      },
    ],
  },
];

export const superAdminPermissions = [
  {
    module: "account",
    permissions: ["read", "write", "update", "delete"],
  },
  {
    module: "admins",
    permissions: ["read", "write", "update", "delete"],
  },
  {
    module: "products",
    permissions: ["read", "write", "update", "delete"],
  },
  {
    module: "bids",
    permissions: ["read"],
  },
  {
    module: "users",
    permissions: ["read", "write", "update", "delete"],
  },
  {
    module: "tickets",
    permissions: ["read", "write", "update"],
  },
  {
    module: "subscribers",
    permissions: ["read", "write", "update", "delete"],
  },
  {
    module: "blogs",
    permissions: ["read", "write", "update", "delete"],
  },
  {
    module: "logs",
    permissions: ["read"],
  },
];

export const mainAdminPermissions = [
  {
    module: "account",
    permissions: ["read", "write", "update", "delete"],
  },
  {
    module: "admins",
    permissions: ["read", "write", "update", "delete"],
  },
  {
    module: "products",
    permissions: ["read", "write", "update", "delete"],
  },
  {
    module: "bids",
    permissions: ["read"],
  },
  {
    module: "users",
    permissions: ["read", "write", "update", "delete"],
  },
  {
    module: "tickets",
    permissions: ["read", "write", "update"],
  },
  {
    module: "subscribers",
    permissions: ["read", "write", "update", "delete"],
  },
  {
    module: "blogs",
    permissions: ["read", "write", "update", "delete"],
  },
  {
    module: "logs",
    permissions: ["read"],
  },
];

export const maintenanceAdminPermissions = [
  {
    module: "account",
    permissions: ["read", "write"],
  },
  {
    module: "products",
    permissions: ["read"],
  },
  {
    module: "bids",
    permissions: ["read"],
  },
  {
    module: "users",
    permissions: ["read"],
  },
  {
    module: "tickets",
    permissions: ["read", "write", "update"],
  },
  {
    module: "subscribers",
    permissions: ["read", "write", "update"],
  },
  {
    module: "logs",
    permissions: ["read"],
  },
];
