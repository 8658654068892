import "./App.scss";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import NotFoundPage from "./pages/NotFoundPage";
import LoginPage from "./pages/LoginPage";
import { useMediaQuery } from "react-responsive";
import Header from "./components/Dashboard/Common/Header";
import MainHeader from "./components/Header/Header";
import { AuthContext } from "./context/AuthContextProvider";
import { useContext, useEffect, useLayoutEffect } from "react";
import ProfilePage from "./components/Dashboard/ProfilePage";
import MySidebar from "./components/Dashboard/Common/Sidebar";
import EditAccountDetails from "./components/Dashboard/EditAccountDetails";
import ChangePassword from "./components/Dashboard/ChangePassword";
import KnowledgeBase from "./components/Dashboard/KnowledgeBase";
import KnowledgeBasePage from "./pages/KnowledgeBasePage";
import ForgotPassword from "./components/Login/ForgotPassword";
import AllUsers from "./components/Dashboard/Users/AllUsers";
import AllProducts from "./components/Dashboard/Products/AllProducts";
import AllAdmins from "./components/Dashboard/Admins/AllAdmins";
import AllSubscribers from "./components/Dashboard/Subscribers/AllSubscribers";
import CreateAdmin from "./components/Dashboard/Admins/CreateAdmin";
import "./components/Dashboard/Profile.css";
import HomePage from "./pages/HomePage";
import AdPage from "./components/AdPage/AdPage";
import DateTime from "./components/DateTime/DateTime";
import DateTimePage from "./pages/DateTimePage";
import StopWatchPage from "./pages/StopWatchPage";
import TimerPage from "./pages/TimerPage";

function App() {
  const { isAuth } = useContext(AuthContext);

  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });

  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };

  return (
    <BrowserRouter>
      <Wrapper>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route
            exact
            path="/full-screen-date-and-time"
            element={<DateTimePage />}
          />
          <Route
            exact
            path="/full-screen-stop-watch"
            element={<StopWatchPage />}
          />
          <Route exact path="/full-screen-timer" element={<TimerPage />} />

          <Route exact path="*" element={<NotFoundPage />} />
          <Route
            exact
            path="/personal-ad-page-for-brands-with-minimum-investment"
            element={<AdPage />}
          />
        </Routes>
      </Wrapper>
    </BrowserRouter>
  );
}

export default App;
