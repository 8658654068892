import React from "react";
import { Link } from "react-router-dom";
import LapsTable2 from "./LapsTable2";

function StopWatchDisplay({
  days,
  hours,
  minutes,
  seconds,
  milliseconds,
  isRunning,
  handlePause,
  handleStart,
  handleLap,
  handleReset,
  buttonState,
  laps,
}) {
  return (
    <div className="p-4">
      <div className="mockbrowser-Outer w-fit m-auto">
        <div className="mockup-browser border-base-300 border mockbrowser-box ">
          <div className="mockup-browser-toolbar">
            <div className="input border-base-300 border bg-white">
              <Link
                to="/personal-ad-page-for-brands-with-minimum-investment"
                className="ad-brand"
                target="_blank"
              >
                Advertise your brand here!
              </Link>
            </div>
          </div>
          <div className="border-base-300 flex justify-center border-t px-[40px] py-[32px]">
            <div className="grid grid-flow-col gap-[16px] text-center auto-cols-max">
              <div className="flex flex-col p-2 timer-time-box rounded-box text-neutral-content">
                <span className="countdown font-mono text-[24px] sm:text-5xl md:text-7xl lg:text-8xl  xl:text-9xl timer-time">
                  <span style={{ "--value": days }}></span>
                </span>
                <span className="text-sm md:text-lg lg:text-xl timer-time ">
                  days
                </span>
              </div>
              <div className="flex flex-col p-2 timer-time-box rounded-box text-neutral-content">
                <span className="timer-time countdown font-mono text-[24px] sm:text-5xl md:text-7xl lg:text-8xl  xl:text-9xl">
                  <span style={{ "--value": hours }}></span>
                </span>
                <span className="timer-time text-sm md:text-lg lg:text-xl">
                  hours
                </span>
              </div>
              <div className="flex flex-col p-2 timer-time-box rounded-box text-neutral-content">
                <span className="timer-time countdown font-mono text-[24px] sm:text-5xl md:text-7xl lg:text-8xl  xl:text-9xl">
                  <span style={{ "--value": minutes }}></span>
                </span>
                <span className="timer-time text-sm md:text-lg lg:text-xl">
                  min
                </span>
              </div>
              <div className="flex flex-col p-2 timer-time-box rounded-box text-neutral-content">
                <span className="timer-time countdown font-mono text-[24px] sm:text-5xl md:text-7xl lg:text-8xl  xl:text-9xl">
                  <span style={{ "--value": seconds }}></span>
                </span>
                <span className="timer-time text-sm md:text-lg lg:text-xl">
                  sec
                </span>
              </div>
              <div className="flex flex-col p-2 timer-time-box rounded-box text-neutral-content min-w-[70px]">
                <span className="timer-time font-mono text-[24px] sm:text-5xl md:text-7xl lg:text-8xl  xl:text-9xl">
                  {milliseconds.toString().padStart(2, "0")}
                </span>
                <span className="timer-time text-sm md:text-lg lg:text-xl">
                  ms
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-[12px] justify-center mt-[16px]">
        <button
          onClick={isRunning ? handlePause : handleStart}
          className={`btn btn-sm ${
            buttonState.startPause ? "btn-primary" : "btn-disabled"
          }`}
        >
          {isRunning ? "Pause" : "Start"}
        </button>
        <button
          onClick={handleLap}
          className={`btn btn-sm ${
            buttonState.lap ? "btn-secondary" : "btn-disabled"
          }`}
          disabled={!buttonState.lap}
        >
          Lap
        </button>
        <button
          onClick={handleReset}
          className={`btn btn-sm ${
            buttonState.reset ? "btn-accent" : "btn-disabled"
          }`}
          disabled={!buttonState.reset}
        >
          Reset
        </button>
      </div>
      <LapsTable2 laps={laps} />
    </div>
  );
}

export default StopWatchDisplay;
