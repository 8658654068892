import React, { useContext, useState } from "react";
import { Upload, Tabs, Button } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import axios from "axios";
import { AuthContext } from "../../context/AuthContextProvider";
import { Dna } from "react-loader-spinner";
import toast from "react-hot-toast";

const { Dragger } = Upload;
const { TabPane } = Tabs;

const FileUpload = ({ userId }) => {
  const [imageUrl, setImageUrl] = useState("");
  const { loginUser, updateLoginUser } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isRemoving, setIsRemoving] = useState(false);

  const beforeUpload = (file) => {
    setErrorMessage("");
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      setErrorMessage("You can only upload JPG/PNG files!");
      return Upload.LIST_IGNORE;
    }
    const isLt200KB = file.size / 1024 < 200;
    if (!isLt200KB) {
      setErrorMessage("Image must be smaller than 200KB!");
      return Upload.LIST_IGNORE;
    }
    return isJpgOrPng && isLt200KB;
  };

  const handleUpload = async ({ file }) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("profile_image", file);
    formData.append("userId", userId);

    try {
      const response = await axios.post(
        "https://super-admin-backend-gold.vercel.app/admin/upload-profile-image",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setImageUrl(response.data.url);
      const updatedUser = {
        ...loginUser,
        profile_image: response.data.url,
      };
      toast.success("Your profile picture is successfully uploaded!");
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);
      updateLoginUser(updatedUser);
    } catch (err) {
      setErrorMessage("Error uploading image.");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveProfilePicture = async () => {
    setIsRemoving(true);
    try {
      const response = await axios.post(
        "https://super-admin-backend-gold.vercel.app/admin/remove-profile-image",
        { userId }
      );

      if (response.status === 200) {
        const updatedUser = { ...loginUser, profile_image: null };
        toast.success("Removed your profile picture is successfully!");
        updateLoginUser(updatedUser);
        setImageUrl(null);
      }
      setIsRemoving(false);
    } catch (error) {
      console.error("Error removing profile image", error);
      toast.error("Error removing your profile picture!");
      setErrorMessage("Error removing profile image.");
      setIsRemoving(false);
    }
  };
  //superadmin testing

  return (
    <div className="flex justify-center items-center ">
      <div className="w-full max-w-md">
        <Tabs
          defaultActiveKey="1"
          centered
          tabBarStyle={{
            color: "#b99653", // Default text color
          }}
        >
          <TabPane tab="Upload New Picture" key="1">
            <div className="upload-div">
              <Dragger
                name="file"
                accept="image/jpeg, image/png"
                beforeUpload={beforeUpload}
                customRequest={handleUpload}
                showUploadList={false}
                disabled={isLoading}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined style={{ color: "#101c4c" }} />
                </p>
                <p className="font-bold text-[0.9rem] sm:text-[1rem] md:text-[1.25rem]">
                  Click or drag file to this area to upload
                </p>
                <p
                  className="ant-upload-hint"
                  style={{ color: "#555", fontWeight: "bold" }}
                >
                  <ul className="w-fit m-auto">
                    <li className="!list-disc text-[0.75rem] sm:text-[0.8rem] md:text-[0.9rem]">
                      Only JPEG/PNG files are allowed
                    </li>
                    <li className="!list-disc text-[0.75rem] sm:text-[0.8rem] md:text-[0.9rem]">
                      File must be smaller than 200KB.
                    </li>
                  </ul>
                </p>
              </Dragger>
              {isLoading && (
                <div className="flex align-items-center flex-col">
                  <Dna
                    visible={true}
                    height="200"
                    width="200"
                    ariaLabel="dna-loading"
                    wrapperStyle={{ margin: "auto", marginBottom: "0px" }}
                    wrapperClass="dna-wrapper"
                  />
                  <p className="text-[16px]">
                    Uploading your image, Please be patient...
                  </p>
                </div>
              )}
              {errorMessage && (
                <p style={{ color: "red", marginTop: "10px" }}>
                  {errorMessage}
                </p>
              )}
              {imageUrl && !isLoading && showSuccessMessage && (
                <div>
                  <p>Image uploaded successfully!</p>
                  <img
                    src={imageUrl}
                    alt="Uploaded"
                    style={{ width: "200px" }}
                  />
                </div>
              )}
            </div>
          </TabPane>

          {/* Conditionally render the "Remove Profile Picture" tab if a profile image exists */}
          {loginUser?.profile_image && (
            <TabPane tab="Remove Current Picture" key="2">
              <div className="remove-div">
                <img
                  src={loginUser.profile_image}
                  alt="Current Profile"
                  style={{
                    width: "auto",
                    height: "120px",
                    margin: "auto",
                    borderRadius: "8px",
                  }}
                />
                <Button
                  type="primary"
                  danger
                  onClick={handleRemoveProfilePicture}
                  style={{ marginTop: "24px" }}
                >
                  {isRemoving ? "Removing..." : "Remove Profile Picture"}
                </Button>
              </div>
            </TabPane>
          )}
        </Tabs>
      </div>
    </div>
  );
};

export default FileUpload;
