import React from "react";
import DateTime from "../DateTime/DateTime";

function Hero() {
  return (
    <div className="container max-w-7xl">
      <div className="row">
        <div className="col-12 col-md-8">
          <DateTime />
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default Hero;
