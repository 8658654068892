// ---- Importing required dependencies (External) ----
// ---- Packages ----
import React from "react";
import { useNavigate } from "react-router-dom";

// ---- Importing required dependencies (Local) ----
// ---- Styles ----
import "./NotFound.css";

// ---- Main function -- Not Found Page.
const NotFound = () => {
  const navigate = useNavigate();

  // Function to navigate to the homepage
  const goToHomepage = () => {
    navigate("/");
  };

  return (
    <section id="not-found">
      <div>
        <div className="flex flex-col max-w-7xl mx-auto scroll-smooth p-20 mb-20 justify-center items-center">
          {/* Heading */}
          <div className="text-[5rem]">404</div>
          <br />
          <br />
          <br />

          {/* Message */}
          <div>Page not found</div>

          {/* Button to go to the homepage */}
          <button
            type="button"
            className="px-4 py-2 mt-5 text-xl font-semibold hover:text-gray-700 transition-all"
            onClick={goToHomepage}
          >
            Go to Homepage
          </button>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
