import { useState } from "react";

const TimerDisplay = ({
  name,
  hours,
  minutes,
  seconds,
  startTimer,
  stopTimer,
  resetTimer,
  isRunning,
  setName,
  setHours,
  setMinutes,
  setSeconds,
  setSound,
}) => {
  return (
    <div className="p-4">
      <div className="mockbrowser-Outer w-fit m-auto">
        <div className="mockup-browser border-base-300 border mockbrowser-box ">
          <div className="mockup-browser-toolbar">
            <div className="input border-base-300 border bg-white flex items-center justify-between">
              <span>{name}</span>
            </div>
          </div>
          <div className="border-base-300 flex justify-center border-t px-[40px] py-[32px]">
            <div className="grid grid-flow-col gap-[16px] text-center auto-cols-max">
              <div className="flex flex-col p-2 timer-time-box rounded-box text-neutral-content">
                <span className="timer-time countdown font-mono text-[24px] sm:text-5xl md:text-7xl lg:text-8xl xl:text-9xl">
                  <span style={{ "--value": hours }}></span>
                </span>
                <span className="timer-time text-sm md:text-lg lg:text-xl">
                  hours
                </span>
              </div>
              <div className="flex flex-col p-2 timer-time-box rounded-box text-neutral-content">
                <span className="timer-time countdown font-mono text-[24px] sm:text-5xl md:text-7xl lg:text-8xl xl:text-9xl">
                  <span style={{ "--value": minutes }}></span>
                </span>
                <span className="timer-time text-sm md:text-lg lg:text-xl">
                  min
                </span>
              </div>
              <div className="flex flex-col p-2 timer-time-box rounded-box text-neutral-content">
                <span className="timer-time countdown font-mono text-[24px] sm:text-5xl md:text-7xl lg:text-8xl xl:text-9xl">
                  <span style={{ "--value": seconds }}></span>
                </span>
                <span className="timer-time text-sm md:text-lg lg:text-xl">
                  sec
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-[12px] justify-center mt-[32px]">
        <button
          onClick={startTimer}
          className="btn btn-success btn-sm"
          disabled={
            isRunning || (hours === 0 && minutes === 0 && seconds === 0)
          }
        >
          Start
        </button>
        <button
          onClick={stopTimer}
          className="btn btn-warning btn-sm"
          disabled={!isRunning}
        >
          Stop
        </button>
        <button onClick={resetTimer} className="btn btn-danger btn-sm">
          Reset
        </button>
        <TimerEditPopUp
          name={name}
          setName={setName}
          setHours={setHours}
          setMinutes={setMinutes}
          setSeconds={setSeconds}
          setSound={setSound}
          hours={hours}
          minutes={minutes}
          seconds={seconds}
        />
      </div>
    </div>
  );
};

export default TimerDisplay;

export function TimerEditPopUp({
  name,
  setName,
  setHours,
  setMinutes,
  setSeconds,
  setSound,
  hours,
  minutes,
  seconds,
}) {
  return (
    <div>
      <button
        className="btn btn-info btn-sm"
        onClick={() => document.getElementById("timer-modal").showModal()}
      >
        Edit
      </button>
      <dialog id="timer-modal" className="modal ">
        <div className="modal-box mockbrowser-box">
          <form method="dialog">
            <button
              type="button"
              className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
              onClick={() => document.getElementById("timer-modal").close()}
            >
              ✕
            </button>
          </form>
          <h3 className="font-bold text-lg">Edit Timer</h3>
          <div className="py-4">
            <label className="block mb-2">Timer Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="input input-bordered w-full bg-white"
            />
            <div className="flex gap-4 mt-4">
              <div>
                <label className="block mb-2">Hours</label>
                <input
                  type="number"
                  value={hours}
                  onChange={(e) => setHours(parseInt(e.target.value, 10))}
                  className="input input-bordered w-full bg-white"
                />
              </div>
              <div>
                <label className="block mb-2">Minutes</label>
                <input
                  type="number"
                  value={minutes}
                  onChange={(e) => setMinutes(parseInt(e.target.value, 10))}
                  className="input input-bordered w-full bg-white"
                />
              </div>
              <div>
                <label className="block mb-2">Seconds</label>
                <input
                  type="number"
                  value={seconds}
                  onChange={(e) => setSeconds(parseInt(e.target.value, 10))}
                  className="input input-bordered w-full bg-white"
                />
              </div>
            </div>
            <div className="mt-4">
              <label className="block mb-2">Alarm Sound</label>
              <input
                type="file"
                onChange={(e) => setSound(e.target.files[0])}
                className="file-input file-input-bordered w-full bg-white"
              />
            </div>
          </div>
          <button
            onClick={() => document.getElementById("timer-modal").close()}
            className="btn btn-primary w-full mt-4"
          >
            Save
          </button>
        </div>
      </dialog>
    </div>
  );
}
