import React from "react";
import Header from "../components/Header/Header";
import DateTime from "../components/DateTime/DateTime";

function DateTimePage() {
  return (
    <div>
      <Header />
      <div className="home-page !justify-center pt-[120px]">
        <DateTime />
      </div>
    </div>
  );
}

export default DateTimePage;
