import * as React from "react";
import Drawer from "@mui/joy/Drawer";
import Button from "@mui/joy/Button";
import DialogTitle from "@mui/joy/DialogTitle";
import ModalClose from "@mui/joy/ModalClose";
import Stack from "@mui/joy/Stack";
import Sheet from "@mui/joy/Sheet";
import Menu from "@mui/icons-material/Menu";
import IconButton from "@mui/joy/IconButton";
import { useMediaQuery } from "react-responsive";
import { AuthContext } from "../../context/AuthContextProvider";
import { useContext } from "react";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import { Link, useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import InfoIcon from "@mui/icons-material/Info";
import LanguageIcon from "@mui/icons-material/Language";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import Box from "@mui/joy/Box";
import Avatar from "@mui/material/Avatar";
import Praveen from "./../Dashboard/Media/male.png";
import DummyUser from "./media/DummyUser.png";

import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";

export function LoggedInButtons({ setOpen }) {
  return (
    <Box>
      <SimpleTreeView>
        <TreeItem itemId="profile" label="Profile">
          <Link to="/Dashboard" onClick={() => setOpen(false)}>
            <TreeItem itemId="dashboard" label="Personal Info" />
          </Link>
          <Link to="/Dashboard/ChangePassword" onClick={() => setOpen(false)}>
            <TreeItem itemId="change-password" label="Change Password" />
          </Link>
        </TreeItem>
        <TreeItem itemId="domains" label="Domains">
          <Link to="/Dashboard/Domains" onClick={() => setOpen(false)}>
            <TreeItem itemId="my-domains" label="My Domains" />
          </Link>
          <Link to="/DomainNameSearch" onClick={() => setOpen(false)}>
            <TreeItem itemId="register-domain" label="Register Domain" />
          </Link>
          <Link to="/Dashboard/TransferDomain" onClick={() => setOpen(false)}>
            <TreeItem itemId="transfer-domain" label="Transfer Domain" />
          </Link>
        </TreeItem>
        <TreeItem itemId="billing" label="Billing">
          <Link to="/Dashboard/Invoices" onClick={() => setOpen(false)}>
            <TreeItem itemId="invoices" label="Invoices" />
          </Link>
        </TreeItem>
      </SimpleTreeView>
    </Box>
  );
}
const MobileLinks = [
  {
    id: 1,
    link: "/DomainNameSearch",
    name: "Domain Search",
    icon: SearchIcon,
  },
  { id: 2, link: "/Goldhistory", name: "About .GOLD", icon: InfoIcon },
  { id: 3, link: "/Blogs", name: "Blogs", icon: HistoryEduIcon },
  { id: 4, link: "/LiveSites", name: "Live Sites", icon: WysiwygIcon },
  { id: 5, link: "/Knowledge", name: "Knowledge Base", icon: WysiwygIcon },
];

export default function MobileSidebar() {
  const [open, setOpen] = React.useState(false);
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const { isAuth, logoutme, loginUser } = useContext(AuthContext);
  const navigate = useNavigate();

  let handleClick = () => {
    if (isAuth === true) {
      logoutme();
    } else {
      navigate("/login");
    }
  };

  return (
    <React.Fragment>
      <IconButton
        variant="soft"
        color="neutral"
        onClick={() => setOpen(true)}
        style={{ background: "#fbcb04" }}
      >
        <Avatar
          alt="Remy Sharp"
          src={isAuth ? Praveen : DummyUser}
          variant="rounded"
          size="lg"
          sx={{
            width: "24px",
            height: "24px",
            background: "white",
            borderRadius: "12px",
          }}
        />
      </IconButton>
      <Drawer
        anchor="right"
        size={isMobile ? "lg" : "lg"}
        variant="soft"
        open={open}
        onClose={() => setOpen(false)}
        slotProps={{
          content: {
            sx: {
              bgcolor: "transparent",
              p: 3,
              boxShadow: "none",
              overflow: "hidden",
            },
          },
        }}
      >
        <Sheet
          sx={{
            borderRadius: "md",
            p: 0,
            gap: 2,
            height: "100%",
            overflow: "auto",
          }}
          className="mobile-drawer"
        >
          <ModalClose />
          <Box component="section" className="mobile-drawer-top-box">
            <Box
              component="section"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "20px",
                marginBottom: "20px",
              }}
            >
              <Box component="section" sx={{ width: "40%" }}>
                <Avatar
                  alt="Remy Sharp"
                  src={isAuth ? Praveen : DummyUser}
                  variant="rounded"
                  size="lg"
                  sx={{
                    width: "100%",
                    height: "100%",
                    background: "white",
                    borderRadius: "12px",
                  }}
                />
              </Box>
              <Box
                component="section"
                sx={{
                  width: "60%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <DialogTitle sx={{ color: "white", paddingBottom: "8px" }}>
                  {loginUser.username ? loginUser.username : ""}{" "}
                </DialogTitle>
                <Stack direction="row" spacing={isAuth ? "12px" : "0px"}>
                  <Button
                    variant="outlined"
                    color="neutral"
                    style={{ background: "#fbcb04" }}
                    size="sm"
                    sx={{ width: "fit-content", display: isAuth ? "" : "none" }}
                  >
                    <Link to="/Dashboard/EditAccountDetails">Edit Profile</Link>
                  </Button>
                </Stack>
              </Box>
            </Box>
          </Box>
          <Box sx={{ p: "20px" }} className="mobile-drawer-links-box">
            {isAuth ? (
              <Stack
                direction="column"
                justifyContent="start"
                useFlexGap
                spacing={1}
                sx={{
                  p: 2,
                  background: "white",
                  borderRadius: "12px",
                  marginTop: "20px",
                  boxShadow: "0px 0px 2px grey",
                }}
              >
                <LoggedInButtons setOpen={setOpen} />
              </Stack>
            ) : null}
          </Box>
          <Box
            component="section"
            sx={{
              height: "fit-content",
            }}
            className="mobile-drawer-bottom-box"
          >
            <Stack
              justifyContent="start"
              useFlexGap
              sx={{
                p: "10px",
                height: "fit-content",
                textAlign: "left",
              }}
            >
              <Button
                variant="text"
                sx={{
                  justifyContent: "flex-start",
                }}
              >
                <Link
                  to="/Contact"
                  className="hover:text-[#fbcb04] !font-[400]"
                >
                  Help & Support
                </Link>
              </Button>
              <Button
                variant="text"
                sx={{
                  justifyContent: "flex-start",
                }}
              >
                <Link to="/Terms" className="hover:text-[#fbcb04] !font-[400]">
                  Terms & Conditions
                </Link>
              </Button>
            </Stack>

            {!isAuth ? (
              <Stack
                direction="row"
                justifyContent="start"
                useFlexGap
                spacing={1}
                sx={{
                  p: 2,
                  borderTop: "1px solid #8080805e",
                  height: "fit-content",
                }}
              >
                <Button
                  color="primary"
                  onClick={function () {}}
                  size="md"
                  variant="outlined"
                  startDecorator={<LoginIcon />}
                  sx={{ borderColor: "#fbcb04", color: "#fbcb04" }}
                >
                  <Link to="/Login">LOGIN</Link>
                </Button>

                <Button
                  variant="solid"
                  color="black"
                  startDecorator={<HowToRegIcon />}
                  style={{ background: "#fbcb04" }}
                >
                  <Link to="/signup"> SIGN UP</Link>
                </Button>
              </Stack>
            ) : (
              <Stack
                direction="row"
                justifyContent="start"
                useFlexGap
                spacing={1}
                sx={{
                  p: 2,
                  borderTop: "1px solid #8080805e",
                  height: "fit-content",
                }}
              >
                <Button
                  variant="outlined"
                  color="neutral"
                  style={{ background: "#fbcb04" }}
                  startDecorator={<LogoutIcon />}
                  onClick={handleClick}
                >
                  LOGOUT
                </Button>
              </Stack>
            )}
          </Box>
        </Sheet>
      </Drawer>
    </React.Fragment>
  );
}
