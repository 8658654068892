import React from "react";

function AdPage() {
  return (
    <div className="ad-page">
      <p className="text-5xl font-semibold italic">Just Rs0.25/min</p>
      <p className="text-2xl">
        Please reach out to 'ads-in-timer-app@gmail.com' for taking our ad
        service.
      </p>
    </div>
  );
}

export default AdPage;
