import React, { useState, useEffect, useRef } from "react";
import TimerDisplay from "./TimerDisplay";

// New TimerDisplay component to render the UI

const SingleTimer = () => {
  const [name, setName] = useState("Timer 1");
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [sound, setSound] = useState(null);
  const intervalRef = useRef(null);

  const startTimer = () => {
    if (isRunning || (hours === 0 && minutes === 0 && seconds === 0)) return;

    setIsRunning(true);
    intervalRef.current = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds > 0) return prevSeconds - 1;

        setMinutes((prevMinutes) => {
          if (prevMinutes > 0) {
            setSeconds(59);
            return prevMinutes - 1;
          }

          setHours((prevHours) => {
            if (prevHours > 0) {
              setMinutes(59);
              setSeconds(59);
              return prevHours - 1;
            }

            clearInterval(intervalRef.current);
            setIsRunning(false);
            triggerAlarm();
            return 0;
          });
          return 0;
        });
        return 0;
      });
    }, 1000);
  };

  const stopTimer = () => {
    clearInterval(intervalRef.current);
    setIsRunning(false);
  };

  const resetTimer = () => {
    clearInterval(intervalRef.current);
    setIsRunning(false);
    setHours(0);
    setMinutes(0);
    setSeconds(0);
  };

  const triggerAlarm = () => {
    if (sound) {
      const audio = new Audio(URL.createObjectURL(sound));
      audio.play();
    } else {
      alert("Time's up!");
    }
  };

  return (
    <TimerDisplay
      name={name}
      hours={hours}
      minutes={minutes}
      seconds={seconds}
      startTimer={startTimer}
      stopTimer={stopTimer}
      resetTimer={resetTimer}
      isRunning={isRunning}
      setName={setName}
      setHours={setHours}
      setMinutes={setMinutes}
      setSeconds={setSeconds}
      setSound={setSound}
    />
  );
};

export default SingleTimer;
