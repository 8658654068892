import * as React from "react";
import Drawer from "@mui/joy/Drawer";
import Button from "@mui/joy/Button";
import ModalClose from "@mui/joy/ModalClose";
import Stack from "@mui/joy/Stack";
import Sheet from "@mui/joy/Sheet";
import Menu from "@mui/icons-material/Menu";
import IconButton from "@mui/joy/IconButton";
import { useMediaQuery } from "react-responsive";
import { AuthContext } from "../../context/AuthContextProvider";
import { useContext } from "react";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import InfoIcon from "@mui/icons-material/Info";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import Box from "@mui/joy/Box";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import LoginIcon from "@mui/icons-material/Login";
import HowToRegIcon from "@mui/icons-material/HowToReg";

export function LoggedInButtons() {
  return (
    <Box>
      <SimpleTreeView>
        <TreeItem itemId="profile" label="Profile">
          <Link to="/Dashboard">
            <TreeItem itemId="dashboard" label="Personal Info" />
          </Link>
          <Link to="/Dashboard">
            <TreeItem itemId="change-password" label="Change Password" />
          </Link>
        </TreeItem>
        <TreeItem itemId="domains" label="Domains">
          <Link to="/Dashboard/Domains">
            <TreeItem itemId="my-domains" label="My Domains" />
          </Link>
          <Link to="/Domain Name Search">
            <TreeItem itemId="register-domain" label="Register Domain" />
          </Link>
          <Link to="/Dashboard">
            <TreeItem itemId="transfer-domain" label="Transfer Domain" />
          </Link>
        </TreeItem>
        <TreeItem itemId="billing" label="Billing">
          <Link to="/Dashboard">
            <TreeItem itemId="invoices" label="Invoices" />
          </Link>
          <Link to="/Dashboard">
            <TreeItem itemId="my-quotes" label="My Quotes" />
          </Link>
        </TreeItem>
      </SimpleTreeView>
    </Box>
  );
}
const MobileLinks = [
  {
    id: 1,
    link: "/DomainNameSearch",
    name: "Domain Search",
    icon: SearchIcon,
  },
  { id: 2, link: "/Goldhistory", name: "About .GOLD", icon: InfoIcon },
  { id: 3, link: "/Blogs", name: "Blogs", icon: HistoryEduIcon },
  { id: 4, link: "/LiveSites", name: "Live Sites", icon: WysiwygIcon },
  { id: 5, link: "/Knowledge", name: "Knowledge Base", icon: WysiwygIcon },
];

export default function MobileOptions() {
  const [open, setOpen] = React.useState(false);
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const { isAuth } = useContext(AuthContext);
  return (
    <React.Fragment>
      <IconButton
        variant="soft"
        color="neutral"
        onClick={() => setOpen(true)}
        style={{ background: "#fbcb04" }}
      >
        <Menu />
      </IconButton>
      <Drawer
        anchor="right"
        size={isMobile ? "lg" : "lg"}
        variant="soft"
        open={open}
        onClose={() => setOpen(false)}
        slotProps={{
          content: {
            sx: {
              bgcolor: "transparent",
              p: 3,
              boxShadow: "none",
              height: "fit-content",
              overflow: "hidden",
            },
          },
        }}
      >
        <Sheet
          sx={{
            borderRadius: "md",
            p: 0,
            gap: 2,
            height: "100%",
            overflow: "auto",
          }}
          className="mobile-drawer mobile-loggedout-drawer"
        >
          <ModalClose />
          <Box
            sx={{ p: "20px", paddingTop: "60px", background: "line" }}
            className=""
          >
            <Stack
              direction="column"
              justifyContent="start"
              useFlexGap
              spacing={1}
              sx={{
                p: 2,
                background: "white",
                borderRadius: "12px",
                boxShadow: "0px 0px 2px grey",
              }}
            >
              {MobileLinks.map((item) => (
                <Button
                  key={item.id}
                  color="primary"
                  onClick={function () {}}
                  size="lg"
                  variant="outlined"
                  startDecorator={<item.icon />}
                  sx={{
                    borderColor: "#8080805e",
                    color: "grey",
                    fontWeight: "400",
                    justifyContent: "flex-start",
                    textAlign: "left",
                    textTransform: "none",
                    display:
                      item.name === "Knowledge Base" && !isAuth ? "none" : "",
                    "&:hover": {
                      borderColor: "#fbcb04",
                      backgroundColor: "transparent",
                      color: "#fbcb04", // To override default hover background color
                    },
                  }}
                >
                  <Link
                    to={item.link}
                    className="hover:text-[#fbcb04] !font-[400]"
                  >
                    {item.name}
                  </Link>
                </Button>
              ))}
            </Stack>
            {isAuth ? null : (
              <Stack
                direction="column"
                justifyContent="start"
                useFlexGap
                spacing={1}
                sx={{
                  background: "white",
                  borderRadius: "12px",
                  boxShadow: "0px 0px 2px grey",
                  marginTop: "24px",
                }}
              >
                <Stack
                  justifyContent="start"
                  useFlexGap
                  sx={{
                    height: "fit-content",
                    textAlign: "left",
                    paddingTop: "12px",
                  }}
                >
                  <Button
                    variant="text"
                    sx={{
                      justifyContent: "flex-start",
                    }}
                  >
                    <Link
                      to="/Contact"
                      className="hover:text-[#fbcb04] !font-[400]"
                    >
                      Help & Support
                    </Link>
                  </Button>
                  <Button
                    variant="text"
                    sx={{
                      justifyContent: "flex-start",
                    }}
                  >
                    <Link
                      to="/Terms"
                      className="hover:text-[#fbcb04] !font-[400]"
                    >
                      Terms & Conditions
                    </Link>
                  </Button>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="start"
                  useFlexGap
                  spacing={1}
                  sx={{
                    p: 2,
                    borderTop: "1px solid #8080805e",
                    height: "fit-content",
                  }}
                >
                  <Button
                    color="primary"
                    onClick={function () {}}
                    size="md"
                    variant="outlined"
                    startDecorator={<LoginIcon />}
                    sx={{ borderColor: "#fbcb04", color: "#fbcb04" }}
                  >
                    <Link to="/Login">LOGIN</Link>
                  </Button>

                  <Button
                    variant="solid"
                    color="black"
                    startDecorator={<HowToRegIcon />}
                    style={{ background: "#fbcb04" }}
                  >
                    <Link to="/signup"> SIGN UP</Link>
                  </Button>
                </Stack>
              </Stack>
            )}
          </Box>
        </Sheet>
      </Drawer>
    </React.Fragment>
  );
}
