import React, { useContext } from "react";
import "./../Dashboard/Common/Styles.css";
import { Link, useNavigate } from "react-router-dom";
import person from "./../../Assets/Media/Generic/male.png";
import { MdOutlineClose } from "react-icons/md";
import Button from "@mui/material/Button";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import { MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LogoutIcon from "@mui/icons-material/Logout";
import SearchIcon from "@mui/icons-material/Search";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AuthContextProvider, {
  AuthContext,
} from "../../context/AuthContextProvider";
import LanguageIcon from "@mui/icons-material/Language";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import { useSearchContext } from "../../context/SearchContext";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "8px 10px 12px",
      background: "linear-gradient(179deg, #fbcb0438, #101c4c00)",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function MainHeaderDropdownMenu() {
  const { isAuth, logoutme, loginUser } = useContext(AuthContext);
  const { handleMenuItemClick } = useSearchContext();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let handleClick2 = async () => {
    if (isAuth === true) {
      await logoutme({ username: loginUser.username });
      navigate("/Login");
      handleClose();
    } else {
      navigate("/Login");
    }
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="text"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          color: "white",
          textTransform: "none",
          marginLeft: "-10px",
          marginTop: "2px",
          padding: "10px 8px",
          fontSize: "1.1rem !important",
        }}
      >
        Hey, {loginUser.username}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Box
          sx={{
            justifyContent: "space-between",
            display: "flex",
            margin: "4px 6px 12px",
          }}
        >
          <div>
            <Typography variant="h6" gutterBottom>
              User Profile 2
            </Typography>
          </div>{" "}
          <button onClick={handleClose} className="mt-[-12px]">
            <MdOutlineClose className="dashboard-header-close-btn" />
          </button>
        </Box>
        <Box
          onClick={handleClose}
          sx={{
            gap: "16px",
            display: "flex",
            justifyContent: "space-between",
            margin: "0px 4px 18px",
          }}
        >
          <div className="avatar">
            <div className="w-24 rounded dashboard-header-profile-pic">
              {/* <img
                src={loginUser.profile_image ? loginUser.profile_image : person}
                alt="Tailwind-CSS-Avatar-component"
                className=""
              /> */}
            </div>
          </div>
          <div className="flex justify-center flex-col align-items-left">
            <Typography variant="h6">{loginUser.username}</Typography>
            <Typography variant="body2" gutterBottom>
              {loginUser.email_id}
            </Typography>
          </div>
        </Box>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={handleClose}
          component={Link}
          to="/Dashboard"
          className="hover:text-black"
        >
          <ListItemIcon>
            <PermIdentityIcon />
          </ListItemIcon>
          <ListItemText primary="My Profile" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleMenuItemClick("My Domains");
          }}
          component={Link}
          to="/Dashboard/Domains"
          className="hover:text-black"
        >
          <ListItemIcon>
            <LanguageIcon />
          </ListItemIcon>
          <ListItemText primary="My Domains" />
        </MenuItem>
        <MenuItem
          onClick={handleClose}
          component={Link}
          to="/Knowledge"
          className="hover:text-black"
        >
          <ListItemIcon>
            <TipsAndUpdatesOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Knowledge Base" />
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <Button
          onClick={handleClick2}
          variant="contained"
          sx={{
            width: "100%",
            background: "#fbcb04",
            color: "black",
            textAlign: "center",
            marginTop: "2px",
            "&:hover": {
              background: "#101c4c",
              color: "white",
            },
          }}
          startIcon={<LogoutIcon />}
        >
          Logout
        </Button>
      </StyledMenu>
    </div>
  );
}
