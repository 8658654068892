import React from "react";
import { useState } from "react";
import { createContext } from "react";
import toast, { Toaster } from "react-hot-toast";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // Optional, if dealing with UTC dates
import timezone from "dayjs/plugin/timezone"; // Optional, if dealing with timezones
import localizedFormat from "dayjs/plugin/localizedFormat"; // Plugin for localized date formats
import relativeTime from "dayjs/plugin/relativeTime"; // Plugin for relative time
import customParseFormat from "dayjs/plugin/customParseFormat";

// Extend dayjs with plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

export const AuthContext = createContext();

export default function AuthContextProvider({ children }) {
  const [isAuth, SetAuth] = useState(
    JSON.parse(localStorage.getItem("isAuth")) || false
  );
  const [loginUser, setLoginUser] = useState(
    JSON.parse(localStorage.getItem("loginUser")) || {}
  );
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")) || []
  );

  const resetState = () => {
    SetAuth(false);
    setLoginUser({});
    setUser([]);
    localStorage.setItem("isAuth", JSON.stringify(false));
    localStorage.removeItem("loginUser");
    localStorage.removeItem("user");
  };

  let loginme = async (data) => {
    try {
      const response = await fetch(
        "https://super-admin-backend-gold.vercel.app/admin/authenticate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (response.ok) {
        const user = await response.json();
        setLoginUser(user);
        SetAuth(true);
        localStorage.setItem("isAuth", JSON.stringify(true));
        localStorage.setItem("loginUser", JSON.stringify(user));
        toast.success(`Welcome back, ${user.role} admin`);
        setTimeout(resetState, 60 * 60 * 1000); // reset state after 1 hour
      } else {
        const errorData = await response.json(); // Parse the error response
        if (errorData.message) {
          console.log("error data is :", errorData);
          toast.error(errorData.message); // Display the error message from the response
        } else {
          toast.error("Please provide correct login details"); // Fallback error message
        }
      }
    } catch (error) {
      toast.error("An error occurred while logging in");
    }
  };

  let logoutme2 = () => {
    resetState();
    toast.error("Sorry to see you go, \n We will be waiting for you!");
  };

  let logoutme = async (data) => {
    console.log("New logout enetered.");
    try {
      const response = await fetch(
        "https://super-admin-backend-gold.vercel.app/admin/logout",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (response.ok) {
        resetState();
        toast.error("Sorry to see you go, \n We will be waiting for you!");
        console.log("Logout successful.");
      } else {
        resetState();
        toast.error("Sorry to see you go, \n We will be waiting for you!");
      }
    } catch (error) {
      resetState();
      toast.error("Sorry to see you go, \n We will be waiting for you!");
    }
  };

  let getData = (data) => {
    setUser(data);
    localStorage.setItem("user", JSON.stringify(data));
  };
  const updateLoginUser = (updatedUser) => {
    setLoginUser(updatedUser);
    localStorage.setItem("loginUser", JSON.stringify(updatedUser));
  };

  const formatDate = (dateString) => {
    const date = dayjs(dateString);
    const twoDaysAgo = dayjs().subtract(2, "day");

    if (date.isAfter(twoDaysAgo)) {
      // Less than 2 days ago, show relative time
      return date.fromNow();
    } else {
      // More than 2 days ago, show formatted date
      return date.format("D MMM, YYYY, hh:mm A [IST]");
    }
  };

  const getDate = (dateString) => {
    const date = dayjs(dateString);
    return date.format("D MMM, YYYY");
  };

  const formatDate2 = (dateString) => {
    // Define the format of the date string
    const format = "M/D/YYYY, h:mm:ss A";

    // Parse the date string using the custom format
    const date = dayjs(dateString, format);

    // Debugging: Print the parsed date to check if it's valid
    if (!date.isValid()) {
      console.error(`Invalid date for string: ${dateString}`);
      return "Invalid date";
    }

    const twoDaysAgo = dayjs().subtract(2, "day");

    if (date.isAfter(twoDaysAgo)) {
      // Less than 2 days ago, show relative time
      return date.fromNow();
    } else {
      // More than 2 days ago, show formatted date
      return date.format("D MMM, YYYY, hh:mm A [IST]");
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        loginme,
        logoutme,
        getData,
        loginUser,
        updateLoginUser,
        formatDate,
        formatDate2,
        getDate,
      }}
    >
      {children}
      <Toaster />
    </AuthContext.Provider>
  );
}
