import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import AuthContextProvider from "./context/AuthContextProvider";
import { SearchProvider } from "./context/SearchContext";
import AdminContextProvider from "./context/AdminContext";

createRoot(document.getElementById("root")).render(
  <>
    <AdminContextProvider>
      <SearchProvider>
        <AuthContextProvider>
          <App />
        </AuthContextProvider>
      </SearchProvider>
    </AdminContextProvider>
  </>
);
