import React, { useState, memo } from "react";
import { DataGrid } from "@mui/x-data-grid";

const LapsTable2 = memo(({ laps }) => {
  const [lapNames, setLapNames] = useState({});

  const handleNameChange = (lapNumber, name) => {
    setLapNames({ ...lapNames, [lapNumber]: name });
  };

  // Columns definition
  const columns = [
    {
      field: "lapNumber",
      headerName: "#",
      width: 30,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      headerName: "Name It",
      width: 200,
      renderCell: (params) => (
        <input
          type="text"
          placeholder="Name this lap"
          className="input input-bordered input-xs w-full max-w-xs"
          value={lapNames[params.row.lapNumber] || ""}
          onChange={(e) =>
            handleNameChange(params.row.lapNumber, e.target.value)
          }
          style={{
            padding: "4px",
            fontSize: "12px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            width: "100%",
          }}
        />
      ),
    },
    { field: "interval", headerName: "Interval", width: 100, align: "left" },
    { field: "lapTime", headerName: "Total", width: 100, align: "left" },
    {
      field: "systemTime",
      headerName: "Recorded at",
      width: 250,
      align: "left",
    },
  ];

  // Rows mapping
  const rows = laps.map((lap, index) => ({
    id: index,
    lapNumber: lap.lapNumber,
    name: lapNames[lap.lapNumber] || "",
    interval: lap.interval,
    lapTime: lap.lapTime,
    systemTime: lap.systemTime,
  }));

  return (
    <div className="mt-[24px]">
      {laps.length > 0 ? (
        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={2}
            disableColumnMenu
            checkboxSelection={true}
            sx={{
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#ffffff",
                color: "#000000",
                fontWeight: "bold",
                position: "sticky",
                top: 0,
                zIndex: 1,
                // minHeight: "40px", // Minimum height of the header row
                // maxHeight: "40px",
                // height: "20px",
              },
              "& .MuiDataGrid-row": {
                borderBottom: "1px solid #e0e0e0",
                // minHeight: "35px", // Minimum height of a row
                // maxHeight: "35px",
                // height: "20px",
              },
              "& .MuiDataGrid-cell": {
                fontSize: "12px",
                // padding: "8px", // Custom padding for cell content
              },
              "& .MuiDataGrid-root": {
                border: "2px solid #e0e0e0",
                borderRadius: "8px",
              },
            }}
          />
        </div>
      ) : null}
    </div>
  );
});

export default LapsTable2;
