import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useState } from "react";

import KnowledgeData from "./KnowledgeData";
import DashboardCard, {
  BlueButton,
  DashboardContent,
} from "./Common/DashboardCard";
import { useMediaQuery } from "react-responsive";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    background: "#989898",
    borderRadius: 22 / 2,
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&::before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&::after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  ".Mui-checked+.MuiSwitch-track": {
    background: "#63b8f7 !important",
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
    color: "#2153ac",
  },
}));

export default function KnowledgeContent({ searchTerm }) {
  const [value, setValue] = React.useState(0);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleArticleClick = (article) => {
    setSelectedArticle(article);
    console.log("Selected:", selectedArticle);
  };

  const handleBackButtonClick = () => {
    console.log("I am clicked");
    setSelectedArticle(null);
  };

  // Filter articles based on the search term
  const filteredData = KnowledgeData.filter((category) =>
    category.articles.some((article) =>
      article.title.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  React.useEffect(() => {
    console.log("Selected:", selectedArticle);
  }, [selectedArticle]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        top: "0px",
        flexWrap: isMobile ? "wrap" : null,
        padding: isMobile ? "40px 0px" : null,
      }}
      className="settings-tabs">
      {isMobile ? null : (
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{
            borderRight: 1,
            borderColor: "divider",
            top: "0px",

            "& .MuiTabs-scroller": {
              borderColor: "red", // Change this to your desired border color
              borderStyle: "solid",
            },
          }}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#fbcb04", // Change this to your desired color
            },
          }}
          className="settings-tabs">
          {filteredData.map((category, index) => (
            <Tab
              key={index}
              label={
                <div>
                  <h1 className="settings-heading">{category.category}</h1>
                </div>
              }
              {...a11yProps(index)}
              onClick={() => handleBackButtonClick()}
            />
          ))}
        </Tabs>
      )}
      {isMobile
        ? !selectedArticle &&
          filteredData.map((category, index) => (
            <div
              key={index}
              className={`flex flex-col gap-y-[20px] ${
                isMobile ? "mb-[20px]" : null
              }`}>
              {category.articles.map((article, articleIndex) => (
                <div
                  key={articleIndex}
                  className="col-12 col-md-6 col-lg-4 col-xl-3"
                  onClick={() => handleArticleClick(article)}>
                  <div className="w-[90%] m-auto knowledge-article h-full">
                    <Typography variant="h6">{article.title}</Typography>
                    <Typography variant="body2">{article.content}</Typography>
                  </div>
                </div>
              ))}
            </div>
          ))
        : !selectedArticle &&
          filteredData.map((category, index) => (
            <TabPanel
              key={index}
              value={value}
              index={index}
              className="w-full">
              <div className="flex flex-wrap py-[16px] gap-y-[20px]">
                {category.articles.map((article, articleIndex) => (
                  <div
                    key={articleIndex}
                    className="col-12 col-md-6 col-lg-4 col-xl-3"
                    onClick={() => handleArticleClick(article)}>
                    <div className="w-[90%] m-auto knowledge-article h-full">
                      <Typography variant="h6">{article.title}</Typography>
                      <Typography variant="body2">{article.content}</Typography>
                    </div>
                  </div>
                ))}
              </div>
            </TabPanel>
          ))}

      {selectedArticle && (
        <TabPanel value={value} index={value}>
          <Box sx={{ p: 2 }}>
            <Typography variant="h6" className="text-[#2153ac]">
              {selectedArticle.title}
            </Typography>
            <div>{selectedArticle.extendedContent()}</div>
            <BlueButton>
              <button onClick={() => handleBackButtonClick()}>Back</button>
            </BlueButton>
          </Box>
        </TabPanel>
      )}
    </Box>
  );
}
