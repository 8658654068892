import React from "react";
import Header from "../components/Header/Header";
import NotFound from "../components/NotFound/NotFound";

function NotFoundPage() {
  return (
    <>
      <Header />
      <NotFound />
    </>
  );
}

export default NotFoundPage;
